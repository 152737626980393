import './NavContainer.css';
import nav_logo from '../img/nav_logo.png';
import nav_select from '../img/nav_select.svg';
import nav_unselect from '../img/nav_unselect.svg';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { Twirl as Hamburger } from 'hamburger-react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeNavMenuOpenValue } from '../App';

function NavContainer() {

    // resizing 시, Hamburger menu 처리 ---------------------------
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });


    let nav_menu = "";
    if (windowSize.width >= 768) {
        nav_menu = <DesktopMenus></DesktopMenus>;
    } else {
        nav_menu = <HamburgerMenu />;
    }


    // END -------------------------------------------------------

    const isMenuOpened = useSelector((state) => state.isOpen);

    const dispatch = useDispatch();
    return (
        <div className="home-nav-container" >
            <nav className="home-nav">
                <Link to="/" onClick={() => {
                    dispatch(changeNavMenuOpenValue(false));
                }}><img className="coLogo" src={nav_logo} alt="home" /></Link>
                {nav_menu}
            </nav>
            {isMenuOpened &&
                <MobileMenus />
            }
        </div>
    );
}

// PC 에서 표시되는 메뉴
function DesktopMenus() {

    return (
        <div className="home-nav-menu">
            <CustomLink className='home-nav-menu-item' to="/introduce" >회사소개<img src={nav_unselect} alt='tag' /></CustomLink>
            {/* <CustomLink className='home-nav-menu-item' to="/reruit" >채용<img src={nav_unselect} alt='tag'/></CustomLink> */}
            <CustomLink className='home-nav-menu-item' to="/shop" >스토어<img src={nav_unselect} alt='tag' /></CustomLink>
            {/* <a className='home-nav-menu-item' href='https://smartstore.naver.com/wish-hive'>스토어<img src={nav_unselect} alt='tag'/></a> */}
        </div>
    );
}

// 모바일에 표시되는 메뉴
function MobileMenus() {

    const dispatch = useDispatch();
    const linkClick = () => {
        dispatch(changeNavMenuOpenValue(false))
    };


    return (
        <div className="mobile-nav-menus" >
            <div className='mobile-nav-menus-list'>
                    <CustomLink className='mobile-nav-menus-item' to="/introduce" onClick={linkClick}>회사소개</CustomLink>
                    {/* <li><CustomLink to="/reruit" onClick={linkClick}>채용</CustomLink></li> */}
                    <CustomLink className='mobile-nav-menus-item' to="/shop" onClick={linkClick}>스토어</CustomLink>
                    {/* <li><a href='https://smartstore.naver.com/wish-hive'>스토어</a></li> */}
            </div>
        </div>
    );
}

// 햄버거 매뉴
function HamburgerMenu() {
    const dispatch = useDispatch();
    const toggleValue = useSelector((state) => state.isOpen);

    return (
        <div className="home-nav-menu">
            <Hamburger toggled={toggleValue} onToggle={(e) => {

                dispatch(changeNavMenuOpenValue(e));
            }} />
        </div>
    );
}



// 선택 시, 효과 주기
function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    let path = props.className + (match ? ' nav-menu-item--active' : '');

    return (
            <Link
                to={to} {...props}
                className={path}
            >
                {children}
            </Link>
        
    );
}


export default NavContainer;