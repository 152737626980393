import './IntroducePage.css';
import homeLogo from '../img/home_logo.svg';
import { Animate } from 'react-simple-animate';

function IntroducePage() {
    return (
        <div className="introducePage">
            <div className='introducePageContent'>
                <div className='introduceDescArea'>
                <Animate play={true}
                        end={{opacity:1}}
                        start={{opacity:0}}
                        duration={1.5}
                    >
                    <p className='introduceDesc'>
                        삶의 터전을 느끼고 만질 수 있어야 한다고 생각하여 만들어진 위시하이브는
                        라이프스타일 산업을 폭 넓게 이해하여 새로운 가치를 찾는 것에 목적을 두고 있습니다.
                    </p>
                    </Animate>
                </div>
                <div className='companyLogoArea'>

                    <Animate play={true}
                        end={{opacity:1}}
                        start={{opacity:0}}
                        duration={2}
                    >
                        <div className="logoContent">
                            <img className="companyLogo" src={homeLogo} alt="logo" />
                            <div className="desc">사용의 편리함을 위한 브랜드 위시하이브</div>
                        </div>

                    </Animate>

                </div>
            </div>
        </div>
    );
}

export default IntroducePage;