import './policyPage.css'

function PolicyPage() {
    return (
        <div className="policy-page">
            <h1>개인정보 처리방침</h1>
            <p>
                주식회사 위시하이브 (이하 ‘회사’라 한다)는 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
            <h2>제1조(개인정보의 처리 목적)</h2>
            <p>회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
            <p>1. 입사 지원 및 인력풀 관리</p>
            <p>입사 지원에 따른 지원자와 의사소통, 입사 전형 진행, 입사지원서 수정, 진행 단계별 결과 안내 및 상시 채용을 위한 인력풀 관리</p>
            <h2>제2조(처리하는 개인정보 항목)</h2>
            <ol>
                <li>
                    <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                    <ol>
                        <li>
                            <p>입사 지원 및 인력풀 관리</p>
                            <ul>
                                <li>(필수 항목) 성명, 이메일주소, 전화번호</li>
                                <li>(선택 항목) 성별, 국적, 사진, 학력사항(학교명, 입학/졸업년월, 전공, 세부전공, 성적, 졸업구분), 경력사항(회사명, 직무, 직위, 부서명, 재직기간), 병역사항, 보훈사항(대상인 경우), 장애사항(대상인 경우), 자격/어학사항(자격 종류, 등급, 점수, 취득일)</li>
                            </ul>
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>제3조(개인정보의 처리 및 보유 기간)</h2>
            <p>① 회사는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
            <p>② 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
            <p>입사 지원 및 인력풀 관리 : 1년, 단 정보주체의 삭제 요청이 있는 경우 지체없이 파기</p>
            <h2>제4조(개인정보의 제3자 제공)</h2>
            <p>① 회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
            <p>② 회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.</p>
            <ol>
                <li>
                    <p>평판 조회(레퍼런스 체크)</p>
                    <ul>
                        <li>제공받는자 : 입사지원자가 근무한 경력이 있는 회사 동료</li>
                        <li>제공하는 개인정보 항목 : 성명</li><li>제공 목적 : 입사지원자의 평판 조회를 위함</li>
                        <li>보유 및 이용 기간 : 평판 조회 종료시까지</li>
                    </ul>
                </li>
            </ol>
            <h2>제5조(개인정보처리 위탁)</h2>
            <p>① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.</p>
            <ol>
                <li>
                    <p>채용시스템 운영</p>
                    <ol>
                        <li>위탁받는 자 : (주)두들린</li>
                        <li>위탁하는 업무의 내용 : 채용시스템(입사지원서 접수, 안내, 평가 등 관리) 운영</li>
                    </ol>
                </li>
            </ol>
            <p>② 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 기술적/관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리/감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</p>
            <p>③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>
            <h2>제6조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)</h2>
            <p>① 정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
            <p>② 제1항에 따른 권리 행사는 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다.</p>
            <p>③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</p>
            <p>④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</p>
            <p>⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</p>
            <p>⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</p>
            <h2>제7조(개인정보의 파기)</h2>
            <p>① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</p>
            <p>② 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
            <ol>
                <li>파기절차: 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
                <li>파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다</li>
            </ol>
            <h2>제8조(개인정보의 안전성 확보 조치)</h2>
            <p>회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
            <ol>
                <li>관리적 조치 : 내부 관리계획 수립, 시행, 개인정보 취급 직원 최소화 및 교육 등</li>
                <li>기술적 조치 : 보안프로그램 설치, 접근통제시스템 설치, 개인정보에 대한 접근 제한 조치, 고유식별정보 암호화 등</li>
                <li>물리적 조치 : 전산실, 자료보관실 등의 출입 통제</li>
            </ol>
            <h2>제9조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</h2>
            <p>① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
            <p>② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
            <ol>
                <li>쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
                <li>쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.</li>
            </ol>
            <h2>
            </h2>
            <p>① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
            <p>
                ▶ 개인정보 보호책임자<br />
                성명 : <br />
                직책 : <br />
                직급 : <br />
                연락처 :, 
            </p>
            <p>
                ▶ 개인정보 민원처리 담당부서<br />
                부서명 : 고객센터<br />
                연락처 : , 
            </p>
            <p>② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</p>
            <h2>제11조(개인정보 열람청구)</h2>
            <p>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
            <p>
                ▶ 개인정보 열람청구 접수·처리 부서<br />
                부서명 : <br />
                연락처 : 
            </p>
            <h2>제12조(권익침해 구제방법)</h2>
            <p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
            <ol>
                <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
                <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
                <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
            </ol>
            <h2>제13조(영상정보처리기기 설치·운영)</h2>
            <p>회사는 아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.</p>
            <ol>
                <li>영상정보처리기기 설치근거·목적 : 시설 안전, 화재 예방, 범죄 예방 및 시설 보호</li>
                <li>
                    <p>설치 대수, 설치 위치 및 촬영 범위 :</p>
                    <ul>
                        <li>설치대수 : (본사) 12대, (이천 물류센터) 189대, (포천물류센터) 25대</li>
                        <li>설치위치 및 촬영 범위 : 출입구, 서버실, 주차장, 주요시설물 등</li>
                    </ul>
                </li>
                <li>
                    <p>관리책임자, 담당부서 및 영상정보에 대한 접근권한자 :</p>
                    <ul>
                        <li>관리책임자 : 정호영/Sr.Office Manager/경영지원팀/1670-0876/hy.jung@bucketplace.net</li>
                        <li>접근권한자 : 이태민/Manager/물류배송팀/1670-0876/newl@bucketplace.net</li>
                    </ul>
                </li>
                <li>
                    <p>영상정보 촬영시간, 보관기간, 보관장소, 처리방법</p>
                    <ul>
                        <li>촬영시간 : 24시간</li>
                        <li>보관기간 : 촬영일로부터 90일 이내</li>
                        <li>처리방법 : 개인영상정보의 목적 외 이용, 제3자 제공, 열람 등 요구에 관한 사항을 기록관리하고, 보관기간 만료 시 삭제합니다.</li>
                    </ul>
                </li>
                <li>영상정보 확인 방법 및 장소 : 관리책임자에게 미리 연락하고 처리 장소(본사/물류센터)를 방문하시면 현장에서 확인 가능합니다.</li>
                <li>정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람.존재확인 청구서로 신청하여야 하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 생명.신체.재산 이익을 위해 필요한 경우에 한해 열람을 허용합니다.</li>
                <li>영상정보 보호를 위한 기술적.관리적.물리적 조치 : 내부관리계획 수립, 접근통제 및 접근권한 제한, 영상정보의 안전한 저장, 전송 기술 적용, 처리기록 보관 등의 보호조치를 적용하고 있습니다.</li>

            </ol>
            <h2>제14조(개인정보 처리방침 변경)</h2>
            <p>
                ① 이 개인정보처리방침은 2022년 1월 25부터 적용됩니다.
            </p>
        </div>

    );
}


export default PolicyPage;