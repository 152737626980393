import { Link } from 'react-router-dom';
import './Footer.css';


function Footer(){
    return(
        <footer className='footer'>
            <div className='footer-content'>
                <div className='footer-info'>
                    <h1 className='company-name'>주식회사 위시하이브(Wish Hive co.,Ltd)</h1>
                    <p className='company-info'>
                        대표이사 : 박준호 / 사업자 등록번호: 160-81-02385 / 서울 금천구 가산디지털1로 2, 우림라이온스밸리2차 503호<br/>
                        T. 02-6445-4430 / F. 02-6445-4431 / E. support@wishhive.co.kr
                    </p>
                    {/* <ul className='footer-contact'>
                        <li className='footer-contact-item footer-contact-item-bold'><Link to="/policy">개인정보처리방침</Link></li>
                    </ul> */}
                </div>
            </div>
        </footer>
    );
}


export default Footer;