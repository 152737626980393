import './App.css';
import { Routes, Route, Outlet, useLocation } from 'react-router-dom';
import { combineReducers, createStore } from 'redux';
import { Provider, useSelector, useDispatch, connect } from 'react-redux';
import HomePage from './home/HomePage';
import ReruitPage from './reruit/ReruitPage';
import NavContainer from './common/NavConainer';
import Footer from './common/Footer';
import PolicyPage from './policy/PolicyPage';
import NotFoundPage from './NotFoundpage';
import IntroducePage from './introduce/IntroducePage';
import ShopPage from './shop/ShopPage';


// Redux 적용

function NavReducer(currentState, action) {
    
  if (currentState === undefined) {
      return {
          isOpen: false,
      };
  }

  const newState = { ...currentState };
 
  if(action.type === NAV_MENU_ACTION){
      newState.isOpen = action.value;
  }


  return newState;
}

const NAV_MENU_ACTION = "NAV_MENU_ACTION";
const navStore = createStore(NavReducer);

export const changeNavMenuOpenValue = (isNavOpen) =>({type: NAV_MENU_ACTION, value : isNavOpen });

// END

function Layout() {
  return (
    <div className='layout'>
      <Provider store={navStore}>
        <NavContainer />
      </Provider>
        <div className='content'>
          <Outlet />
        </div>
      <Footer />
    </div>
  );
}

function App() {
  const rootReducer = combineReducers({NavReducer});
  return (
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/introduce' element={<IntroducePage />} />
          <Route path='/reruit' element={<ReruitPage />} />
          <Route path='/policy' element={<PolicyPage />} />
          <Route path='shop'>
            <Route path='' element={<ShopPage />} />
            <Route path='test' element={<Test />} />
          </Route>
        </Route>
        <Route path='*' element={<NotFoundPage/>}/>
      </Routes>
      
  );
}

function Test(){
  window.location.href = 'https://naver.com';
  return (
    <div>
      Loading
    </div>
  )
}

export default App;
