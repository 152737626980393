import './ShopPage.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import companyLogo from '../img/home_logo.svg';
import rightArr from '../img/right_arrow.svg';
import ImageGallery from 'react-image-gallery';
import { Animate } from 'react-simple-animate';

const imgList = [
    {
        original: '../img/sample1.png',
        thumbnail: '../img/sample1.png',
    },
    {
        original: '../img/sample2.png',
        thumbnail: '../img/sample2.png',
    },
    {
        original: '../img/sample3.png',
        thumbnail: '../img/sample3.png',
    },
    {
        original: '../img/sample4.png',
        thumbnail: '../img/sample4.png',
    }
];

function ShopPage() {
  

    return (
        <div className='shopPage'>

            <div className='shopPageLayout'>


                <div className='sampleImageContent'>

                    <Animate play={true}
                        end={{ transform: "translateY(0px)" }}
                        start={{ transform: "translateY(300px)" }}
                    >
                        <ImageGallery items={imgList}
                            useBrowserFullscreen={false}
                            showFullscreenButton={false}
                            showPlayButton={false}
                            autoPlay={true}
                            disableSwipe={true}
                            slideInterval={5000}
                            lazyLoad={true}
                        />
                    </Animate>
                </div>
                <div className='goShoppingContent_layout'>
                    <div className='goShoppingContent'>
                        <img className='companylogo' src={companyLogo} alt='logo'></img>
                        <p className='desc'>사용의 편리함을 위한 브랜드 위시하이브</p>
                        <div className='goShopping'><a href='https://smartstore.naver.com/wish-hive'>Go Shopping <img src={rightArr} alt="r_arr"></img></a></div>
                        <hr style={{ border: 'solid 2px #FC840B' }} />
                        <p className='complayName'>주식회사 위시하이브(Wish Hive co., Ltd)</p>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ShopPage;