import homeLogo from '../img/home_logo.svg';
import './HomePage.css';
import {Animate} from 'react-simple-animate';

function HomePage() {
    return (
        <Animate play={true}
                end={{opacity:1}}
                start={{opacity:0}}
                duration={2}
            >
        <div className='homeContent' >
            
            <div className="homeLayout">
                <div className="logoContent">
                    <img className="companyLogo" src={homeLogo} alt="logo" />
                    <div className="desc">인테리어의 새로운 가치를 만들어내다</div>
                </div>
            </div>
            
        </div>
        </Animate>
    );
}

export default HomePage;