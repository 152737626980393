import React from 'react';
import reportWebVitals from './reportWebVitals';
import './index.css';
import App from './App';
import ReactDomClient from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom';


const rootElement = document.getElementById('root');

const root = ReactDomClient.createRoot(rootElement);

root.render(

  <BrowserRouter>
    <App />
  </BrowserRouter>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
